import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import {
  ExtPC,
  ExtRM,
  IntRM,
  LidarPoints,
  DeerValleyBlackDiamondLodge,
  DroneIndustrial,
  DroneGreenRoof2,
  DroneNight1,
  DroneNight2,
} from "../assets/images/featured-projects";
import { technologiesLiDARScanToBIMConstructionLiDAR } from "../templates/videos/index.js";
import { ACTIVE_PAGE } from "../components/nav/utils";

const images = [
  DroneNight2,
  DroneNight1,
  DroneGreenRoof2,
  DroneIndustrial,
  DeerValleyBlackDiamondLodge,
  LidarPoints,
  ExtPC,
  IntRM,
  ExtRM,
];

export default function FeaturedProjectsPage() {
  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.FEATUREDPROJECTS}>
      <Seo
        title="Featured Projects"
        description="Some of Robotic Imaging's previous projects - come see what we can do"
      />

      <header className="site__header">
        <h1 className="header__motto header__motto--no-margin">
          Featured Projects
        </h1>
      </header>

      <main className="content">
        <div className="featuredProjects">
          <div className="featuredProjects__card">
            <video
              src={technologiesLiDARScanToBIMConstructionLiDAR}
              className="featuredProjects__image"
              autoPlay
              loop
              muted
              playsInline
            />
          </div>
          {images.map((obj, i) => {
            // console.log(obj);
            return (
              <div className="featuredProjects__card" key={i}>
                <img className="featuredProjects__image" src={obj} alt="" />
              </div>
            );
          })}
        </div>
      </main>
    </SiteWrapper>
  );
}
